import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { NgbdSortableHeader } from '../bootstrap-sortable-header/sortable-header.directive';
import { TwoDigitDecimaNumberDirective } from 'src/app/common/shared/directives/two-digit-decimal.directive';
import { CheckTermsConditionComponent } from 'src/app/components/check-terms-condition/check-terms-condition.component';
import { SelectPaymentOptionsComponent } from '../shared/select-payment-options/select-payment-options.component';
import { EftPaymentComponent } from 'src/app/components/eft-payment/eft-payment.component';
import { DatepickerWithFooterComponent } from 'src/app/components/datepicker-with-footer/datepicker-with-footer.component';
import { SharedTranslateModule } from './shared-translate.module';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';

@NgModule({
  imports: [
    CommonModule,
    SharedTranslateModule,
    NgbDatepickerModule,
    FormsModule
  ],
  declarations: [
    NgbdSortableHeader,
    TwoDigitDecimaNumberDirective,
    SelectPaymentOptionsComponent,
    EftPaymentComponent,
    DatepickerWithFooterComponent,
    CheckTermsConditionComponent
  ],
  
  exports: [
    NgbdSortableHeader, 
    TwoDigitDecimaNumberDirective,
    SelectPaymentOptionsComponent,
    EftPaymentComponent,
    DatepickerWithFooterComponent,
    CheckTermsConditionComponent
  ]
})
export class SharedOBPPModule {}