import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-datepicker-with-footer',
  templateUrl: './datepicker-with-footer.component.html',
  styleUrls: ['./datepicker-with-footer.component.css']
})
export class DatepickerWithFooterComponent implements OnInit {

  @Input() model: NgbDateStruct | undefined | string;  
  @Input() minDate: NgbDateStruct = { year: 1900, month: 1, day: 1 };;
  @Input() maxDate: NgbDateStruct = { year: 10000, month: 1, day: 1 };;
  @Input() errorState?: boolean = false;

  @Output() modelChange = new EventEmitter<NgbDateStruct | undefined | string>();
  @Output() errorStateChanged = new EventEmitter<boolean>();

  today: NgbDateStruct;

  constructor() { 
    const now = new Date();
    this.today = {
      year: now.getFullYear(),
      month: now.getMonth() + 1,
      day: now.getDate()
    };}

  ngOnInit(): void {    
  }

  onDateSelect(date: NgbDateStruct | undefined) {
    this.model = date;
    this.modelChange.emit(this.model);
  }

  setToday() {
    this.model = this.today;
    this.modelChange.emit(this.model);
  }

  clearDate() {
    this.model = undefined;
    this.modelChange.emit(this.model);
  }

  onDateChange(event: any) {
    // trigger error state event if string goes emprty
    event.target.value === '' ? this.errorStateChanged.emit(true) : this.errorStateChanged.emit(false);
    this.modelChange.emit(event);
  }
}
