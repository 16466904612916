<div class="container">
    <div class="row col-md-12">
    <!-- Begin Left section -->
    <div class="col-md-6">
        <h1 class="txt-puro-blue fw-bold error-header">Sorry…</h1>
        <p>
            We were unable to process your request.
        </p>
        <p>
            Please try one of the following methods to find the information you are looking for:
        </p>
        <p>
        <ul>
            <li>
                Go to the Purolator <a href (click)="redirectToHome()">Home Page</a>.

            </li>
            <li>
                Click on your browser's back button to return to the previous page.
            </li>
        </ul>
    </div>
    <!-- End Left section -->
    <!-- Begin Right section -->
    <div class="col-md-6">
        <h1 class="txt-puro-blue fw-bold error-header">Désolé!</h1>
        <p>
            Nous sommes incapables de traiter votre requête.
        </p>
        <p>
            Veuillez essayer l'une des méthodes suivantes pour trouver l'information que vous recherchez :
        </p>
        <p>
        <ul>
            <li>
                Ouvrez la <a href (click)="redirectToHome()">page</a> d'accueil du site de Purolator.
            </li>
            <li>
                Cliquez sur le bouton de retour arrière de votre navigateur pour retourner à la page précédente.
            </li>
        </ul>
    </div>
    <!-- End Right section -->
</div>
</div>