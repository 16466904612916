import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from '../global/local-storage.service';
import { ServicesDataConfiguration } from 'src/app/data-config/services-data-config';

@Injectable({
  providedIn: 'root'
})
export class SelectPaymentOptionService {
  servicesDataConfiguration = new ServicesDataConfiguration();

  constructor(
    private http: HttpClient,
    private localStorageService: LocalStorageService) { }

  // Auxiliar method to get the saved credit card details
  
  getCreditCardDetails(email: string): Observable<any> {
    let lang = this.localStorageService.get('lang');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Accept: '*/*',
      }),
      params: new HttpParams().set('userEmailId', email).set('ts', Date.now()),
    };
    return this.http.get(
      this.servicesDataConfiguration.apiBaseURL +
      this.servicesDataConfiguration.creditCardDetails.replace(
        'LANG_AUTH',
        lang
      ),
      httpOptions
    );
  }
}
