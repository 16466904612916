<div>
    <form> 
        <span>{{textDescription}}</span>
    <div class="dv_sp_ip">
        <span>{{paymentDate}}</span>
        
        
        <app-datepicker-with-footer  
           [(model)]="model"
           [minDate]="minDate"
           [maxDate]="maxDate"
           [errorState]="eftPaymentDateError"
           (modelChange)="onFieldChange($event, 'eftPaymentDate')"
           (errorStateChanged)="onFieldChange($event, 'eftPaymentDate')"
        ></app-datepicker-with-footer>

        <div *ngIf="eftPaymentDateError" class="error-block">{{ 'ALL_FIELDS_TEXT' | translate }}</div>
    </div>
    
    <div class="dv_sp_ip">
        <span>{{payerName}}</span>
        <input type="text" maxlength="25" [ngClass]="eftPayerNameError ? 'inputError' : 'inputText'" (change)="onFieldChange($event, 'eftPayerName')" name="eftPayerName " required>
        <div *ngIf="eftPayerNameError" class="error-block">{{ 'ALL_FIELDS_TEXT' | translate }}</div>
    </div>

    <div class="dv_sp_ip">
        <div class="sp_img">
            <span>{{reference}}</span>
            <img class="icon_tooltip" src="..\..\..\..\..\assets\images\icon-tooltip.png" title="{{'TEXT_EFT_TOOLTIP' | translate}}" >
        </div>
        <input type="number" maxlength="75" [ngClass]="eftReferenceNbrError ? 'inputError' : 'inputText'" (change)="onFieldChange($event, 'eftReferenceNbr')" name="eftReferenceNbr" required>
        <div *ngIf="eftReferenceNbrError" class="error-block">{{ 'ALL_FIELDS_TEXT' | translate }}</div>
    </div>
    </form> 
</div>