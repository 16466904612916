<div>
    <div class="modal-header d-flex justify-content-center"
        [ngClass]="modalType == 'error' ? 'error' : modalType == 'warning' ? 'warning' : 'success'">
        <h4 class="modal-title">{{'REJECT_USER_TITLE_TEXT' | translate}}</h4>
    </div>
    <div class="modal-body text-center">
        {{'REJECT_USER_CONFIRM_TEXT' | translate}}
        <div class="alert" role="alert" *ngIf="displayErrorMsgBlock">
            <div class="container">
                <div class="col-xs-6 error">
                    <ul>
                        <li>{{errMsg | translate}}</li>
                    </ul>
                </div>
            </div>
        </div>
        <div class="form-group">
            <label>{{'REJECT_USER_REASON_TEXT' | translate}}:</label>
            <textarea class="form-control" rows="3" #rejectJustification></textarea>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-center">
        <button class="btn btn-primary btn-block primary-btn" (click)="confirmUserRejection()">{{'REJECT_USER_TEXT' | translate}}</button>
        <button class="btn white-bg sec-button" (click)="cancelUserRejection()">{{'CANCEL' | translate}}</button>
    </div>
</div>