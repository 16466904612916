import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { InvoicePayment } from 'src/app/common/models/invoicePayment';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class InvoicePaymentService {

  constructor(private http: HttpClient) { }

  saveInvoicePayment(invoicePayment: InvoicePayment): Observable<any> {
    return this.http.post(environment.invoiceUrl + `/`, invoicePayment);

  }
  
}
