<section>
  <div class="term-condition-container">
    <div class="col-xs-9">
      <h2>
        <strong class="ng-binding"
          >Purolator Inc. Credit Card Consent Agreement (the “Consent
          Agreement”)</strong
        >
      </h2>
      <p class="ng-binding">
        <strong><u class="ng-binding">Important: </u> </strong>Please carefully
        read the following terms set out in this Consent Agreement. This Consent
        Agreement is between Purolator Inc. (<strong class="ng-binding"
          >"Purolator"</strong
        >,<strong class="ng-binding">"we" </strong> or
        <strong class="ng-binding">"us"</strong>) and you, the authorized
        individual or organization (the
        <strong class="ng-binding">"Cardholder"</strong> or
        <strong class="ng-binding">"You"</strong>) which requires your consent
        to Purolator’s inputting and storing your credit card credentials into
        Purolator’s information technology systems (the
        <strong class="ng-binding">"Systems"</strong> ) to be used to pay for
        and process transactions as described in this Consent Agreement. After
        reading this Consent Agreement, if you select the “I Consent” button
        below you acknowledge that you understand and agree to be bound by all
        the terms and conditions as set out herein.
      </p>
      <ol type="1" start="1">
        <li>
          <p class="ng-binding">
            <u class="ng-binding">Consent to Stored Credentials.</u> You hereby
            consent to Purolator storing your credit card information (each such
            credit card and accompanying information being a
            <strong class="ng-binding"> "Stored Credential"</strong>) in
            Purolator’s Systems where your credit card will be stored and used
            to process payment transactions ("Payment Transactions") for
            Purolator services. You also consent to your Stored Credential being
            stored by Purolator’s third party payment processor. You acknowledge
            that your Stored Credential(s) may contain personal information. You
            consent to Purolator and its third party payment processors using
            such personal information and Stored Credentials to process Payment
            Transactions in accordance with this Consent Agreement. You
            acknowledge and agree that you are responsible for the completeness
            and accuracy of the Stored Credential stored in Purolator’s Systems.
            To the fullest extent permissible by applicable law, you acknowledge
            and agree that Purolator will have no liability associated with or
            arising from your failure to maintain accurate information,
            including your failure to receive important information and updates
            about or related to this Consent Agreement. If Purolator has
            reasonable grounds to suspect that any information you provided to
            us is untrue, inaccurate or incomplete, we may terminate or suspend
            this Consent Agreement without notice or liability and/or refuse to
            provide any Purolator services to you. We regularly review the
            security measures relating to our storage, however, we cannot
            guarantee that information you transmit or store via the Internet is
            entirely secure.
          </p>
        </li>

        <li>
          <p class="ng-binding">
            <u class="ng-binding">Use of Stored Credential.</u> You hereby
            consent that your Stored Credential may be used by Purolator, or its
            third party payment processors i) to process Payment Transactions
            for Purolator services (whether individual shipments or
            pre-authorized or recurring shipments), including processing charges
            and other fees in accordance with section 6; (ii) as permitted in
            accordance with Purolator’s Privacy Statement available
            <!-- ngIf: FromEnglish --><span
              ng-if="FromEnglish"
              class="ng-scope"
            >
              <a
                href="https://www.purolator.com/en/legal/privacy.page?"
                target="_blank"
                class="ng-binding"
                >here</a
              ></span
            ><!-- end ngIf: FromEnglish --><!-- ngIf: FromFrench -->
            and iii) to process any required refunds.
          </p>
        </li>

        <li>
          <p class="ng-binding">
            <u class="ng-binding">Modifications by you.</u> You may make changes
            to your Stored Credential(s) through your customer profile. Each
            time that you add or replace a credit card, or a credit card detail,
            which form part of the Stored Credentials, you must review and
            consent to the then current version of this Consent Agreement in
            order to make such change.
          </p>
        </li>

        <li>
          <p class="ng-binding">
            <u class="ng-binding"
              >Consent Duration, Withdraw and/or Termination.</u
            >
            This Consent Agreement shall remain in effect until the later of the
            following: i) you withdraw your consent including by removing your
            Stored Credentials; ii) a Stored Credential is no longer effective
            (including but not limited to an expired or declined credit card) as
            reasonably determined by us. In each case this Agreement shall be
            deemed to have terminated, but not before your last order for
            services has been finally invoiced and processed including but not
            limited to processing any refunds or charges. You may withdraw your
            consent provided hereunder or remove all Stored Credential(s)
            through your account or by contacting Purolator using the “Live
            Chat” feature or appropriate telephone numbers on Purolator’s
            website found at www.purolator.com. The Stored Credential may remain
            stored in a tokenized form by Purolator and or its third party
            payment processors for a period of up to one year after your consent
            has been withdrawn. We may terminate or suspend this Consent
            Agreement at any time, with or without notice for any reason in our
            sole discretion, including, but not limited to, if your Purolator
            shipping account is not in good standing or any of your payments,
            whether partial or full, are in default. Upon termination (or
            suspension) of this Consent Agreement, you remain liable for all
            payments and other obligations you have incurred hereunder, in
            addition to any Purolator services you request after the date of
            termination or suspension.
          </p>
        </li>

        <li>
          <p class="ng-binding">
            <u class="ng-binding">Amendments.</u> Purolator reserves the right,
            in its sole discretion, to amend the terms of this Consent Agreement
            at any time. We will notify you of any amendments made to this
            Consent Agreement by posting a new version with notice that we deem
            to be reasonable in the circumstances, including such notice on our
            website at
            <a href="https://www.purolator.com/en/home.page" target="_blank"
              >www.purolator.com</a
            >. To the fullest extent permitted under applicable law, your
            continued use after any such amendment(s) constitutes your
            acceptance of this Consent Agreement as amended.
          </p>
        </li>

        <li>
          <p class="ng-binding">
            <u class="ng-binding">Charges, Fees and Taxes.</u> The charges/fees
            composing a Payment Transaction will be calculated in accordance
            with the rates applicable at the time of tendering your shipment,
            and will include all applicable taxes, charges and fuel and other
            surcharges as set out in the Purolator Terms and Conditions of
            Service available
            <!-- ngIf: FromEnglish --><span
              ng-if="FromEnglish"
              class="ng-scope"
            >
              <a
                href="https://www.purolator.com/en/legal/terms-conditions-service.page"
                target="_blank"
                class="ng-binding"
                >here</a
              ></span
            ><!-- end ngIf: FromEnglish --><!-- ngIf: FromFrench -->
            as amended from time to time (the<strong class="ng-binding">
              "Purolator Service Terms"</strong
            >), or as otherwise set out in your Services Pricing Agreement (or
            similar agreement) in effect at the time such shipment is so
            tendered. You are also solely responsible for any interest, fees,
            charges or expenses related to any payment or any non-processed
            payment transaction for any reason other than due to our error or
            omission, as determined in our sole discretion.
          </p>
        </li>

        <li>
          <p class="ng-binding">
            <u class="ng-binding">Disputes.</u> All disputes, including but not
            limited to, billing matters concerning Purolator services, shall be
            governed by the Purolator Terms and Conditions of Service available
            <!-- ngIf: FromEnglish --><span
              ng-if="FromEnglish"
              class="ng-scope"
            >
              <a
                href="https://www.purolator.com/en/legal/terms-conditions-service.page"
                target="_blank"
                class="ng-binding"
                >here</a
              ></span
            ><!-- end ngIf: FromEnglish --><!-- ngIf: FromFrench -->
            or a Services Pricing Agreement (or similar service agreement if
            any) in effect between us and you at the time of tendering the
            shipments in question but only to the extent where such agreement
            expressly supersedes the Purolator Service Terms.
          </p>
        </li>
        <li>
          <p class="ng-binding">
            <u class="ng-binding">Limitation of Liability.</u> YOU UNDERSTAND
            AND AGREE THAT IN NO EVENT WILL PUROLATOR, ITS PARENT, AFFILIATE OR
            SUBSIDIARIES, AND EACH OF THEIR RESPECTIVE EMPLOYEES, OFFICERS,
            DIRECTORS, REPRESENTATIVES OR AGENTS (EACH A
            <strong class="ng-binding"> "PARTY"</strong> ,TOGETHER, THE
            <strong class="ng-binding"> "PARTIES"</strong>) BE RESPONSIBLE OR
            LIABLE TO YOU OR ANY THIRD PARTY, AS RELATED TO THIS CONSENT
            AGREEMENT, A STORED CREDENTIAL OR A PAYMENT TRANSACTION, FOR ANY
            DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, COMPENSATORY, SPECIAL,
            PUNITIVE OR EXEMPLARY DAMAGES OR LOSSES OF ANY KIND, INCLUDING BUT
            NOT LIMITED TO DAMAGES FOR LOSS OF PROFITS OR EARNINGS, LOSS OF
            ANTICIPATED PROFITS OR EARNINGS, GOODWILL, USE, DATA OR OTHER
            INTANGIBLE LOSSES WHICH MAY BE INCURRED, REGARDLESS OF THE TYPE OF
            CLAIM OR CAUSE OF ACTION, WHETHER SUCH CLAIM OR CAUSE ARISES IN
            CONTRACT, TORT, NEGLIGENCE OR OTHERWISE, OR COULD HAVE REASONABLY
            BEEN FORESEEN BY YOU OR ANY THIRD PARTY. NO PARTY WILL BE LIABLE TO
            YOU OR A THIRD PARTY FOR ANY FAILURE TO PERFORM ANY OBLIGATIONS SET
            OUT HEREIN, WHETHER DIRECTLY OR INDIRECTLY, DUE TO THE FAILURE OF
            ANY EQUIPMENT, TRANSMISSION OR TECHNICAL PROBLEMS, LABOUR DISPUTES,
            WAR, NATURAL DISASTER, ACT OF TERRORISM, EXPLOSION, ACT OF GOD OR
            ANY OTHER EVENT BEYOND OUR REASONABLE CONTROL.
          </p>
        </li>
        <li>
          <p class="ng-binding">
            <u class="ng-binding">Indemnification.</u> To the fullest extent
            permitted by applicable law, you agree to indemnify, defend and hold
            harmless each Party from any loss, claim, liability, damages,
            demand, costs, charges and fines in connection with, arising out of
            or due to your breach of this Consent Agreement.
          </p>
        </li>
        <li>
          <p class="ng-binding">
            <u class="ng-binding">Eligibility and Conduct.</u> By selecting “I
            Consent” below, you represent and warrant that you:
          </p>
          <ul>
            <li class="ng-binding">
              are the age of majority in accordance with the laws of your
              province/territory of residence;
            </li>
            <li class="ng-binding">
              are the individual duly authorized on behalf of yourself or your
              organization to store any payment credential as a Stored
              Credential hereunder;
            </li>
            <li class="ng-binding">
              shall not breach the terms of this Consent Agreement;
            </li>
            <li class="ng-binding">
              shall only use the credit card credentials and provide the payment
              information, as permitted by law;
            </li>
            <li class="ng-binding">
              shall not consent to this Consent Agreement in order to conduct
              any fraudulent, unlawful or abusive acts;
            </li>
            <li class="ng-binding">
              shall not disrupt or interfere with the security operation of, or
              otherwise abuse the functionality of storing information on the
              Purolator Systems;
            </li>
            <li class="ng-binding">
              shall not attempt to obtain unauthorized access in relation to
              other Purolator Systems; and
            </li>
            <li class="ng-binding">
              shall comply with any other requirements, restrictions or
              limitations imposed by Purolator from time to time.
            </li>
            <p></p>
          </ul>
        </li>

        <li>
          <p><u class="ng-binding"> General Terms.</u></p>
          <ul>
            <li class="ng-binding">
              This Consent Agreement shall be construed in accordance with and
              shall be governed by the laws of the Province of Ontario or the
              province or Territory where the cardholder resides and the laws of
              Canada applicable therein.
            </li>
            <li class="ng-binding">
              Our failure or delay to exercise or enforce any provision or right
              contained herein shall not be deemed a waiver or such provision or
              right; any waiver of any provision or right shall be expressly set
              out in writing to be effective.
            </li>
            <li class="ng-binding">
              Any provision of this Consent Agreement that is prohibited or
              unenforceable in any jurisdiction shall, as to such jurisdiction,
              be ineffective to the extent of such prohibition or
              unenforceability without invalidating the remaining provisions
              hereof or affecting the validity or enforceability of such
              provision in any other jurisdiction.
            </li>
            <li class="ng-binding">
              Headings are for convenience only and not included for purposes of
              interpretation.
            </li>
            <li class="ng-binding">
              You may not assign this Consent Agreement, in whole or in part, at
              any time.
            </li>
            <li class="ng-binding">
              This Consent Agreement constitutes the entire agreement between
              you and us relating to the matters herein raised.
            </li>
            <li class="ng-binding">
              The terms of this Consent Agreement that by their nature are
              intended to survive the termination or expiration of this Consent
              Agreement (including liability, indemnification and privacy
              obligations) shall survive.
            </li>

            <p></p>
          </ul>
          <p></p>
        </li>
        <li>
          <p class="ng-binding">
            <u class="ng-binding"> Contact.</u>
            You may contact Purolator through the “Live Chat” feature or
            appropriate telephone numbers on Purolator’s website found at
            www.purolator.com.
          </p>
        </li>
      </ol>
    </div>
  </div>

  <iframe src="/assets/terms.pdf" class="pdf" id="pdf-iframe"></iframe>

  <div class="action-bar">
    <button class="btn btn-primary mr-2" (click)="acceptTermsCondition()">Accept</button>
    <button class="btn btn-primary mr-2" (click)="printTermsCondition()">Print</button>
  </div>
</section>
