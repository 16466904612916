import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'obpp-payment-loader-component',
  templateUrl: './obpp-payment-loader.component.html',
  styleUrls: ['./obpp-payment-loader.component.scss']
})
export class OBPPPaymentLoaderComponent implements OnInit {

  constructor() { }

  ngOnInit() {

  }
}