import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MonerisCheckout } from 'src/app/common/models/monerisCheckout';
import { CCDetails } from 'src/app/common/models/paymentOptions';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
  
export class MonerisIntegrationService {

  constructor(private http: HttpClient) { }

  monerisPreloadRequest(monerisPayload: MonerisCheckout): Observable<any> {
    return this.http.post(environment.monerisUrl + `/preload/${monerisPayload.language}`, monerisPayload);
  }

  monerisReceiptRequest(ticketId: any, currency: string, invoiceList: any, userEmail: any, createMethod: any, alternativeEmail: string, monerisEnv: string): Observable<any> { 

    const receiptPayload: any = {
      monerisDetails: {
        environment: monerisEnv,
        ticket: ticketId
      },
      currency,
      invoiceList,
      userEmail,
      createMethod
    };

    if (alternativeEmail.length > 0) {
      receiptPayload.alternativeEmail = alternativeEmail;
    }
    
    return this.http.post(environment.monerisUrl + '/receipt', receiptPayload);

  }

  monerisErrorHandling(errorCode: number, errorMessage?: string) {

    const errorPayload = {
      errorCode: errorCode,
      errorMessage: errorMessage
    }

    // FIX ME: This endpoint will be like this until US PCIV40-96 be developed. Once is done,
    // we can change this placeholder to the correct one;
    return this.http.post(environment.monerisUrl, errorPayload);

  }

  monerisUpdateInvoicesDataRequest(userId: string, createMethod: string, ticketId: string, invoicesList: any, currency: string, monerisEnv: string): Observable<any> { 

    const payload = {
      moneris_details: {
        environment: monerisEnv,
        ticket: ticketId,
        currency
      },
      invoices : invoicesList,
      createMethod,
      userId
    };
    
    return this.http.post(environment.monerisUrl + '/updateInvoicesData', payload);
  }

  cpsaPayWithSavedCardRequest(ccDetails: CCDetails | null, invoiceList: any, invoiceCurrency: string, amount: number, userEmail: any, alternativeEmail: string): Observable<any> {
    const payload = {
      invoiceCurrency,
      invoiceList,
      ccToken: ccDetails?.ccToken,
      ccExpiry: ccDetails?.ccExpiry,
      ccPayerName: ccDetails?.ccPayerName,
      amount: amount.toFixed(2).toString(),
      userEmail,
      alternativeEmail
    };
    return this.http.post(environment.monerisUrl + "/payWithSavedCard", payload);
  }

  getMonerisScriptUrl(): Observable<any> {
    return this.http.get(environment.monerisUrl + '/getScriptUrl', {});
  }

  getMonerisEnvironment(): Observable<any> { 
    return this.http.get(environment.monerisUrl + '/getEnvironment', {});
  }

  injectScriptOnIndexFile(scriptUrl: string) {
    const script = document.createElement('script');
    script.src = scriptUrl;
    document.body.appendChild(script);
  }
}
