export interface PaymentOptions { 
    placeholder?: string;
    ccDetails?: CCDetails;
    paymentOption: PaymentOption;
}

export interface CCDetails {
    ccMask: string;
    ccType: CreditCardsType;
    creditCardstatus: string;
    ccExpiry: string;
    ccToken: string;
    ccPayerName: string;
}

export enum PaymentOption {
    SAVED_CREDIT_CARD = 'SAVED_CREDIT_CARD',
    MONERIS = 'MONERIS',
    EFT_REMITTANCE = 'EFT_REMITTANCE',
}

export enum CreditCardsType {
    VISA = 'Visa',
    MASTERCARD = 'Master',
    AMEX = 'Amex',
}