import { Component, OnInit } from '@angular/core';
import { DataSharingService } from 'src/app/services/login-page/data-sharing.service';

@Component({
  selector: 'obpp-loader-component',
  templateUrl: './obpp-loader.component.html',
  styleUrls: ['./obpp-loader.component.scss']
})
export class OBPPLoaderComponent implements OnInit {

  constructor(public dataSharingService: DataSharingService) { }

  ngOnInit() {

  }
}